<template>
	<v-container v-intersect="resetForm" class="pa-0">
		<v-card elevation="0" v-show="register" min-height="400">
			<v-form
				id= "regiForm"
				ref= "regForm"
				v-model="regValid"
				lazy-validation
			>

			<v-row no-gutters>
				<v-col cols="12" class="text-subtitle-1 font-weight-medium">ACCOUNT DETAILS</v-col>
			
				<v-col cols="12">
					<v-text-field
						v-model= "regEmail"
						ref= "regEmail"
						label="Email"
						hint= "Ex. : juandelacruz@email.com"
						:error="regEmailError"
						:error-messages="regEmailErrorMsg"
						:loading="regEmailLoading"
						validate-on-blur
						:rules= "regEmailRules"
						@keyup= "validateEMail()"
						class="pt-1"
					></v-text-field>
				</v-col>
			
				<v-col cols="6">
					<v-text-field
						v-model="regUsername"
						ref="regUsername"
						label="Username"
						hint= "Ex. : juandelacruz"
						:error="regUserError"
						:error-messages="regUserErrorMsg"
						:loading="regUserLoading"
						validate-on-blur
						:rules= "regUserRules"
						@keyup= "validateUser()"
						class="pt-1"
					></v-text-field>
				</v-col>
			
				<v-col cols="6">
					<v-text-field
						v-model= "regPassword"
						label="Password"
						hint= "Must be atleast 8 characters"
						validate-on-blur
						:rules= "regPasswordRules"
						:type="regPasswordInput ? 'text' : 'password'"
						:append-icon="regPasswordInput ? 'mdi-eye' : 'mdi-eye-off'"
						@click:append="regPasswordInput = !regPasswordInput"
						class="pt-1"
					></v-text-field>
				</v-col>
			
				<v-col cols="12" class="text-subtitle-1 font-weight-medium">PERSONAL INFORMATION</v-col>
			
				<v-col cols="6">
					<v-text-field
						class="uppercase-input pt-1"
						v-model="regLname"
						ref= "regLname"
						label="Last Name"
						hint= "Ex. : Dela Cruz"
						:error="regLnameError"
						:error-messages="regLnameErrorMsg"
						:loading="regLnameLoading"
						validate-on-blur
						:rules= "regLnameRules"
						@keyup= "uniqueName('lname')"
						@blur="regLname = trimName(regLname)"
					></v-text-field>
				</v-col>
				
				<v-col cols="6">
					<v-text-field
						class="uppercase-input pt-1"
						v-model="regFname"
						ref= "regFname"
						label="First Name"
						hint= "Ex. : Juan"
						:error="regFnameError"
						:error-messages="regFnameErrorMsg"
						:loading="regFnameLoading"
						validate-on-blur
						:rules= "regFnameRules"
						@keyup= "uniqueName('fname')"
						@blur="regFname = trimName(regFname)"
					></v-text-field>
				</v-col>
			
				<v-col cols="6">
					<v-text-field
						class="w-100 uppercase-input pt-1"
						v-model="regMname"
						ref= "regMname"
						label="Middle Name"
						:error="regMnameError"
						:error-messages="regMnameErrorMsg"
						:loading="regMnameLoading"
						validate-on-blur
						:rules= "regMnameRules"
						@keyup= "uniqueName('mname')"
						@blur="regMname = trimName(regMname)"
					></v-text-field>
				</v-col>
				
				<v-col cols="6">
					<v-select
						class="w-100 pt-1"
						v-model="regSuffix"
						ref= "regSuffix"
						id= "regSuffix"
						label="Suffix Name"
						:error="regSnameError"
						:error-messages="regSnameErrorMsg"
						:loading="regSnameLoading"
						:items="nameSuffix"
						@change= "uniqueName('sname')"
						clearable
					></v-select>
				</v-col>
				
				<v-col cols="12">
					<v-checkbox
						v-model="acceptTermsAndPolicy"
						:rules="[v => !!v || 'You must agree to continue']"
						required
						dense
						class="ma-0 pa-0"
					>
						<template v-slot:label>
							<div class="text-caption">
								Agree with 
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<a
											v-on="on"
											target="_self"
											href="javascript:void(0)"
											class="text-decoration-none"
											@click.stop="openTerms();"
										>Terms</a>
									</template>
									Open Terms of use
								</v-tooltip>
								and 
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<a
											v-on="on"
											target="_self"
											href="javascript:void(0)"
											class="text-decoration-none"
											@click.stop="openPolicy();"
										>Privacy Policy</a>
									</template>
									Open Privacy Policy
								</v-tooltip>
							</div>
						</template>
					</v-checkbox>
				</v-col>
				
				<v-col cols="12" class="mx-auto text-center">
					<v-btn
						min-width="100"
						:disabled="!regValid"				  
						:loading="regLoading"
						color="primary"
						class="mr-4"
						@click="regValidate()"
					>
						REGISTER
					</v-btn>
				</v-col>
				
				<v-col cols="12" class="mt-3 text-caption" style="line-height: 1rem;">
					<strong>Registered already?</strong> login or recover your account,<br/>strictly one account per applicant only.
				</v-col>
				
			</v-row>
					
			</v-form>
		</v-card>
		
		<v-card elevation="0" v-show="!register" min-height="400">
			
			<v-card-title>
				<span class="mx-auto">CONFIRM YOUR ACCOUNT</span>
			</v-card-title>
			
			<v-card-text>
				<v-row>
					<v-col cols="12" class="body-1 text-center px-0">
						Please enter the 6 character code we sent to <strong>{{ regEmail }}</strong>
					</v-col>
				</v-row>
				
				<v-row no-gutters>
					<v-col cols="8" class="mx-auto text-center">
						<v-text-field
							class="centered-input uppercase-input"
							v-model="verificationCode"
							placeholder="VERIFICATION CODE"
						></v-text-field>
					</v-col>
					
					<v-col cols="12" class="mx-auto text-center pt-2">
						<v-btn
							min-width="100"
							color="primary"
							:loading="btnConfirmCode"
							@click="confirmCode()"
						>
							CONFIRM
						</v-btn>
					</v-col>
					
					<v-col cols="12" class="text-center pt-7">
						<v-btn
							v-if="countDown > 0"
							disabled
							text
						>
							RESEND CODE ({{ countDown }})
						</v-btn>
						
						<v-btn
							v-else
							color="primary"
							:loading="btnResendCode"
							text
							@click="resendCode()"
						>
							RESEND CODE
						</v-btn>
					</v-col>
					
					<v-col cols="12" class="text-center pt-7">
						<v-btn depressed @click="register = !register">
							CANCEL
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-container>
	
</template>

<script>

import { AUTH_REGISTER } from '@/store/actions/auth';
import { PAGE_DIALOG_ON, PAGE_ERROR_CONNECTION, PAGE_SEND } from '@/store/actions/page';

var timeoutEmail = null;
var timeoutUser = null;
var timeoutName = null;

export default {
	data: () => ({
		verificationCode: "",
		register: true,
		acceptTermsAndPolicy: false,
		countDown : 0,
		
		btnConfirmCode: false,
		btnResendCode: false,
		
		regEmailError: false,
		regEmailErrorMsg: "",
		regEmail: "",
		regEmailLoading: false,
		regUsername: "",
		regUserError: false,
		regUserErrorMsg: "",
		regUserLoading: false,
		regLname: "",
		regFname: "",
		regMname: "",
		regSuffix: "",
		regPassword: "",
		regLnameError: false,
		regLnameErrorMsg: "",
		regLnameLoading: false,
		regFnameError: false,
		regFnameErrorMsg: "",
		regFnameLoading: false,
		regMnameError: false,
		regMnameErrorMsg: "",
		regMnameLoading: false,
		regSnameError: false,
		regSnameErrorMsg: "",
		regSnameLoading: false,
		regPasswordInput: false,
		regValid: true,
		regLoading: false,
		nameSuffix: ['Jr.', 'Sr.', 'I', 'II', 'III', 'IV', 'V'],
		regEmailRules: [
			v => !!v || 'E-mail is required',
			v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
		],
		regUserRules: [
			v => !!v || 'Username is required',
			v => (v && v.length >= 3) || 'Must be atleast 3 characters',
		],
		regPasswordRules: [
			v => !!v || 'Password is required',
			v => (v && v.length >= 8) || 'Must be atleast 8 characters',
		],
		regLnameRules: [
			v => !!v || 'Last name is required',
			v => (v && v.length >= 2) || 'Must be atleast 2 characters',
			v => !/[^a-zA-ZñÑ-\s]+/.test(v) || 'Must be alpha characters only',
		],
		regFnameRules: [
			v => !!v || 'First name is required',
			v => (v && v.length >= 2) || 'Must be atleast 2 characters',
			v => !/[^a-zA-ZñÑ-\s]+/.test(v) || 'Must be alpha characters only',
		],
		regMnameRules: [
			v => (v.length == 0 || v.length >= 2) || 'Must be atleast 2 characters',
			v => !/[^a-zA-ZñÑ-\s]+/.test(v) || 'Must be alpha characters only',
		],
	}),
	methods: {
		openTerms(){
			const val = 'terms';
			this.$store.dispatch(PAGE_DIALOG_ON, { val });
		},
		openPolicy(){
			const val = 'policy';
			this.$store.dispatch(PAGE_DIALOG_ON, { val });
		},
		resetForm(){
			this.$refs.regForm.resetValidation();
		},
		regValidate(){
			if (this.$refs.regForm.validate()) {
				this.regLoading = true;
				
				this.$http.post('register/register', { 
					lname: 		this.regLname,
					fname: 		this.regFname,
					mname: 		this.regMname,
					sname: 		this.regSuffix,
					user:	  	this.regUsername,
					email:	  	this.regEmail,
					password: 	this.regPassword,
				}).then(() => {
					this.register = !this.register;
					
					this.countDown = 60;
					this.countDownTimer();
				}).finally(() => {
					this.regLoading = false;
				});
			}
		},
		confirmCode(){
			this.btnConfirmCode = true;
			this.$http.get('register/verifyAccount', {
				params:{
					code : this.verificationCode,
					email: this.regEmail
				}
			}).then((resp) => {
				this.verificationCode = "";
				
				this.regLname = "";
				this.regFname = "";
				this.regMname = "";
				this.regSuffix = "";
				this.regUsername = "";
				this.regEmail = "";
				this.regPassword = "";
					
				const token = resp.data.token;
				const msg = resp.data.msg;
				
				if( resp.data.token ){
					this.$store.dispatch(AUTH_REGISTER, { token, msg }).
					then(() => {
						if( typeof this.$route.name !== 'undefined' && this.$route.name != '' )this.$router.push('/');
					});
				}
			})
			.finally(() => {
				this.btnConfirmCode = false;
			});
		},
		resendCode(){
			this.btnResendCode = true;
			this.$http.post('register/resendCode', {
				email: this.regEmail
			}).then(() => {
				this.countDown = 60;
				this.countDownTimer();
				this.$store.dispatch(PAGE_SEND);
			}).catch(() => {
				this.$store.dispatch(PAGE_ERROR_CONNECTION);
			}).finally(() => {
				this.btnResendCode = false;
			});
		},
		validateEMail(){
			// Clear the timeout if it has already been set.
			// This will prevent the previous task from executing
			// if it has been less than <MILLISECONDS>
			
			clearTimeout(timeoutEmail);
			if( /.+@.+\..+/.test(this.regEmail) && this.regEmail != '' ){
				const app = this;
				// Make a new timeout set to go off in 500ms
				timeoutEmail = setTimeout(function (){
					
					app.regEmailLoading = true;
					
					app.$http.get('register/uniqueEmail', { params:{ email: app.regEmail } })
					.then((response) => {
						app.regEmailLoading = false;
						if( response.data.count == 0 ){
							app.regEmailError= false;
							app.regEmailErrorMsg= "";
						}else{
							app.regEmailError= true;
							app.regEmailErrorMsg= "Email already exist";
						}			
					}).catch(() =>{
						app.regEmailLoading = false;
					});
				
				}, 500);
			}
		},
		validateUser(){
			clearTimeout(timeoutUser);
			if( this.$refs.regUsername.value.length > 2 ){
				
				const app = this;
				
				timeoutUser = setTimeout(function () {
					app.regUserLoading = true;
					
					app.$http.get('register/uniqueUser', { params:{ user: app.regUsername } })
					.then((response) => {
						app.regUserLoading = false;
						if( response.data.count == 0 ){
							app.regUserError= false;
							app.regUserErrorMsg= "";
						}else{
							app.regUserError= true;
							app.regUserErrorMsg= "Username already exist";
						}			
					}).catch(() =>{
						app.regUserLoading = false;
					});
				}, 500);
			}
		},
		uniqueName(el){
			clearTimeout(timeoutName);
			
			if( this.$refs.regLname.value.length > 2 && this.$refs.regFname.value.length > 2 
				&& !/[^a-zA-ZñÑ-\s]+/.test(this.regLname) && !/[^a-zA-ZñÑ-\s]+/.test(this.regFname) && !/[^a-zA-ZñÑ-\s]+/.test(this.regMname)
			){
				
				const app = this;
				
				timeoutName = setTimeout(function () {
					if( el == 'lname'){
						app.regLnameLoading = true;
					}else if( el == 'fname'){
						app.regFnameLoading = true;
					}else if( el == 'mname'){
						app.regMnameLoading = true;
					}else{
						app.regSnameLoading = true;
					}
					
					app.$http.get('register/uniqueName',
						{ params:{
							lname: app.regLname,
							fname: app.regFname,
							mname: app.regMname,
							sname: app.regSuffix,
						}
					})
					.then((response) => {
						app.regLnameLoading = app.regFnameLoading = app.regMnameLoading = app.regSnameLoading = false;
						app.regLnameError = app.regFnameError = app.regMnameError = app.regSnameError = false;
						app.regLnameErrorMsg = app.regFnameErrorMsg = app.regMnameErrorMsg = app.regSnameErrorMsg = "";
						if( response.data.count != 0 ){
							if( el == 'lname'){
								app.regLnameError = true;
								app.regLnameErrorMsg = "Name already exists";
							}else if( el == 'fname'){
								app.regFnameError = true;
								app.regFnameErrorMsg = "Name already exists";
							}else if( el == 'mname'){
								app.regMnameError = true;
								app.regMnameErrorMsg = "Name already exists";
							}else{
								app.regSnameError = true;
								app.regSnameErrorMsg = "Name already exists";
							}
						}		
					}).catch(() =>{
						app.regLnameLoading = app.regFnameLoading = app.regMnameLoading = app.regSnameLoading = false;
					});
				}, 500);
			}
		},
		trimName(text){
			return text.replace(/ +/g, ' ').replace(/-+/g, '-').replace(/^-/g, '').toUpperCase().trimStart().trim();
		},
		countDownTimer() {
			if(this.countDown > 0) {
				setTimeout(() => {
					this.countDown -= 1
					this.countDownTimer()
				}, 1000)
			}
		},
	},
	beforeDestroy(){
		clearTimeout(timeoutEmail);
		clearTimeout(timeoutUser);
		clearTimeout(timeoutName);
	}
}
</script>

<style scoped>
    .centered-input >>> input {
		text-align: center
    }
	
	.uppercase-input >>> input {
		text-transform: uppercase
    }
</style>